* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

body {
  overflow-x: hidden !important;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  font-family: "Nunito", sans-serif;
}

/* </---Golnal CSS--> */

ul {
  padding: 0 !important;
  list-style: none;
}

p {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: Nunito;
}

.titel {
  color: #000;
  text-align: center;
  font-size: 20px;
}

.golbalbtn {
  width: 100%;
  height: 48px;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  background: #ffc533;
  outline: none;
  border: 0;
}

.innerInput input,
.form-control {
  border-radius: 10px !important;
  border: 2px solid #1a1302 !important;
  min-height: 48px !important;
  position: relative;
}

.innerInput img {
  display: block !important;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 99;
}
.pr-4 {
  padding-right: 40px !important;
}

form.inputTag {
  width: 100%;
}

.loginBtn {
  padding: 10px 18px;
  height: 48px;
  border: 0;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  background-color: transparent;
  transition: all ease-in-out 0.3s;
  border-radius: 10px;
}

/* .loginBtn:hover {
  background-color: #ffc533;
} */

.flex {
  display: flex;
  flex-wrap: wrap;
}

/* </---Golnal CSS--> */

::placeholder {
  color: #606060;
  font-size: 14px;
  font-family: Nunito;
  font-weight: 500;
}

.form-control:focus,
.form-control:hover {
  outline: 0;
  border-color: rgba(255, 197, 51, 0.582) !important;
  box-shadow: 0 0 0 0.25rem #ffc53352 !important;
}

.select__control.css-13cymwt-control {
  border-radius: 10px !important;
  border: 2px solid #1a1302 !important;
  position: relative;
}

.css-t3ipsp-control {
  border-radius: 10px !important;
  border-color: rgba(255, 197, 51, 0.582) !important;
  box-shadow: 0 0 0 0.25rem #ffc53352 !important;
  position: relative;
}

.select__placeholder,
.select__single-value.css-1dimb5e-singleValue {
  text-align: left !important;
}

.checkInBtn .react-datepicker__input-container > input {
  width: 311px !important;
  height: 48px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #1a1302;
}

.checkInBtn .react-datepicker__input-container > input:focus,
.checkInBtn .react-datepicker__input-container > input:hover {
  outline: 0;
  border-color: rgba(255, 197, 51, 0.582) !important;
  box-shadow: 0 0 0 0.25rem #ffc53352 !important;
}

button.react-datepicker__navigation.react-datepicker__navigation--previous,
button.react-datepicker__navigation.react-datepicker__navigation--next {
  border: 0 !important;
}
.mapContainer button {
  /* border: 0 !important; */
  outline: none !important;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: initial !important;
  flex-flow: initial !important;
}

.carousel .carousel-status {
  position: absolute;
  top: auto !important;
  bottom: 0 !important;
  right: 10px !important;
  padding: 5px;
  border-radius: 10px;
  background: #e3f6f5;
  color: #000 !important;
  text-shadow: none !important;
  font-family: Nunito;
  font-weight: 700;
  font-size: 14px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: #fff;
  /* box-shadow: inset 0 0 4px #ffc533; */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffc533;
  border-radius: 10px;
}

.modal-dialog.modal-fullscreen {
  width: 100%;
}
