.container {
  width: 375px;

  .golbalText {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    font-family: Nunito;
    line-height: 1.3;

    span {
      font-weight: 400;
    }

    img {
      vertical-align: super;
      width: 60%;
    }
  }

  .position__Arrow {
    position: absolute;
    left: 0;

    .backBtn {
      button {
        border: 0;
        outline: none;
        border-radius: 10px;
      }
    }
  }

  .closeImg {
    .logoHeader > p {
      font-size: 26px;
      font-family: Fredoka One;
      font-style: normal;
      font-weight: 400;
      color: #000;
    }

    .headerImg {
      display: flex;
      gap: 15px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-top: 20px !important;
  }

  .toggleBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 30px;
  }

  .wprcontainer {
    width: 100%;
    margin: 0 auto;
    background: #fff;
    height: 100vh;
    position: relative;
    padding: 20px 0;

    .userWpr {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;
      margin-top: 50px;

      .userInner {
        width: 300px;
        .logo > h1 {
          padding-bottom: 100px;
          font-family: Fredoka One;
          font-style: normal;
          font-weight: 400px;
          font-size: 60px;
          color: #000000;
        }

        .contentContainer {
          width: 100%;
          margin-block: 50px;
          .firstHead {
            color: #000;
            text-align: center;
            font-family: Nunito;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 43px;
          }

          .textImgBox {
            margin-top: 12px;
            position: relative;
            width: 100%;
            height: 100px;
            .overImage {
              position: absolute;
              top: 40px;
              z-index: 1;
            }
            .rightImg {
              transform: translate(-10px, 0%);
            }
          }
        }
      }
    }

    .emailContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 70%;

      .checkInBtn {
        display: flex;
        align-items: center;

        .checkInDate {
          // margin-right: 10px;
          width: 351px !important;
        }

        .checkOutDate {
          margin-left: 10px;
        }

        button {
          border-radius: 10px;
          outline: navajowhite;
          background: transparent;
          border: 2px solid #1a1302;
          padding: 12px 18px;
          text-align: center;
          align-items: center;
          display: flex;
          justify-content: center;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          line-height: 1;
        }

        span {
          width: 15px;
          height: 2px;
          background: black;
          border-radius: 10px;
        }

        .inputPepole {
          width: 48px;
          height: 48px;
          text-align: center;
          color: #000;
          font-size: 16px;
          font-family: Nunito;
          font-weight: 700;
        }

        .innerInput {
          width: 48px;
        }

        .addBtn {
          border-radius: 10px;
          background: #ffc533;
          border: 0;
          color: #000000 !important;
        }

        .removeBtn {
          border-radius: 10px;
          background: rgba(255, 197, 51, 0.5);
          border: 0;
          color: #000000 !important;
        }
      }

      .invite-box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border: 2px solid #000;
        padding: 10px;
        border-radius: 10px;

        p {
          margin: 0;
          width: 240px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .copyBg {
          border-radius: 10px;
          padding: 7px;
          background: #ffc533;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .checkwpr {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: calc(100% - 150px);
    }

    .accountDiv {
      h4 {
        font-family: Nunito;
        font-weight: 700;
        text-align: left;
        padding-bottom: 10px;
      }

      .myAccount {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        h3 {
          font-family: Nunito;
          font-weight: 700;
        }
      }

      .accountContainer {
        position: relative;
        margin-bottom: 30px;
        cursor: pointer;

        .accountWpr {
          justify-content: space-between;
          align-items: center;
          border-radius: 10px;
          border: 2px solid #1a1302;
          background: #fff;
          width: 100%;
          padding: 10px 15px;

          .ImgArrow {
            height: 30px;
            width: 30px;
          }

          .acccount_text {
            p {
              text-align: left;
              margin: 0;
              padding-bottom: 10px;
              overflow: hidden;
              width: 220px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              max-height: 45px;
            }
          }
        }

        ul {
          position: absolute;
          right: 15px;
          top: auto;
          bottom: -10px;
          margin-bottom: 0;
          gap: 10px;

          .bg-primary {
            color: #000;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            background: #e3f6f5 !important;
            padding: 4px 3px;
          }
        }

        .tripsTop {
          position: absolute;
          left: 0px;
          top: -15px;

          .bg-primary {
            background: #000 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
          }
        }
        .likeCount {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 6px;
          .likeThumbSmall {
            background: #ffc533;
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 7px;
            img {
              width: 10px;
              height: 10px;
            }
          }
          p {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }

      .likesDetail {
        text-align: left !important;
      }

      .likedUser {
        display: flex;
        align-items: center;
        gap: 15px;
      }

      .accountMainDiv {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px);
        justify-content: space-between;
      }

      .feedbackBottomBtn {
        margin-top: 30px;
      }
    }
  }

  .cardContainer {
    width: 100vw;
    max-width: 100%;
    height: 440px;
    position: relative;

    .blurDiv {
      filter: blur(5px);
    }

    .centerImg {
      position: absolute;
      top: 50px;
      left: auto;
      right: 50px;
    }

    .overlay_sec {
      position: absolute;
      z-index: 9;
      left: 0px;
      right: -10px;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 20px;

      img {
        width: 100%;
        height: 420px;
        object-fit: cover;
        border-radius: 20px;
      }

      .gotItbtn {
        width: 105px;
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }

  .cardName {
    box-sizing: border-box;
    width: 100%;
    height: 400px;
    left: 5px;
    top: 10px;
    position: relative;
    background-color: #fff;
    background: no-repeat;
    // box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    cursor: pointer;

    .cardBox {
      width: 320px;
      border-radius: 8px;
      background: #fff;
      margin: 0 14px;
      position: absolute;
      bottom: 20px;
      justify-content: space-between;
      align-items: center;

      li {
        padding-left: 8px;

        .yellowBackgroundInfo {
          border: 1px solid #ffc533;
          border-radius: 10px;
          background-color: #ffc533;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        span {
          font-weight: 700;
          font-size: 20px;
          padding: 12px;
        }

        h3 {
          color: #000;
          font-family: Nunito;
          font-size: 14px;
          font-weight: 700;
          margin: 0;
          text-align: left;
          width: 220px;
          line-break: auto;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 35px;
        }

        p {
          margin: 0;
          text-align: left;
        }
      }
    }
  }

  .card h3 {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: #fff;
  }

  .swipe {
    position: absolute;
    width: 100%;
    padding: 2px;
  }

  .swipe-overlay_left_img {
    position: absolute;
    top: 50px;
    left: 50px;
    transform: translate(-50%, -50%);
    // font-size: 2rem;
    // font-weight: bold;
    color: white;
  }

  .swipe-overlay_right_img {
    position: absolute;
    top: 50px;
    right: -10px;
    left: auto;
    transform: translate(-50%, -50%);
    // font-size: 2rem;
    // font-weight: bold;
    color: white;
  }

  .spinner-border {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 70% auto 0;
    width: 50px;
    height: 50px;
  }

  .mapMarker {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 31.881px;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    // flex-shrink: 0;
    border-radius: 10px;
    background-color: #000000;
    :focus-visible {
      outline: none !important;
    }
  }

  .mapContainer {
    border-radius: 10px;
    width: 100%;
    height: 300px;
  }

  .noProperty {
    margin-top: 150px;
    padding: 20px;
  }

  // a > div > img {
  //   display: none;
  // }

  p.legend {
    display: none;
  }

  p.carousel-status {
    // margin-top: 70px;
    // margin-right: 10px;
    border-radius: 10px;
    background: #ffffff;
    color: #000 !important;
    text-shadow: none !important;
    font-family: Nunito;
    font-weight: 700;
    font-size: 14px !important;
  }

  .carouselDiv {
    .carousel-slider {
      .slider-wrapper {
        height: 100% !important;

        ul {
          li {
            height: 100%;
            min-height: 250px;
            max-height: 310px;
            max-width: 410px;
            display: flex;
            align-items: center;
            // padding: 2px;

            img {
              // height: 100vh;
              display: flex;
              align-items: center;
              justify-content: center;
              object-fit: cover;
              // aspect-ratio: 16/9;
            }
          }
        }
      }
    }
  }

  .propertyName {
    ul {
      li {
        text-align: left;

        h3 {
          color: #000;
          font-family: Nunito;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        p {
          text-align: left;
          color: #000;
          font-family: Nunito;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .guestDiv {
      .bg-primary {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        background: #e3f6f5 !important;
        padding: 4px 10px;
      }
    }
  }

  .description {
    text-align: left;
    // padding: 25px 0;

    h3 {
      color: #000;
      font-family: Nunito;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    p {
      text-align: left;
      margin-bottom: 0;
      color: #000;
      font-family: Nunito;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: relative;
      padding-left: 20px;

      &:before {
        position: absolute;
        content: "";
        left: 5px;
        top: 7px;
        width: 7px;
        height: 7px;
        background-color: #000;
        border-radius: 100%;
      }
    }
  }

  .gmnoprint {
    display: none !important;
  }

  button.gm-control-active.gm-fullscreen-control {
    display: none !important;
  }
}

@media (max-width: 280px) {
  .modal-fullscreen {
    width: 100%;
  }

  .responsiveImg {
    height: 30px;
    width: 30px;
  }

  .container {
    .cardContainer {
      margin: 0 auto;
    }
    .cardName {
      .cardBox {
        width: 250px;
        gap: 25px;
        li {
          .yellowBackgroundInfo {
            border: 1px solid #ffc533;
            border-radius: 10px;
            background-color: #ffc533;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          h3 {
            font-size: 14px;
            width: 150px;
          }
        }
      }
    }
    .toggleBtn {
      margin-left: 0;
    }
    .container .wprcontainer .accountDiv .accountMainDiv {
      height: 100vh;
    }
  }
}

.hline {
  width: 24px;
  border-top: 2px solid #1a1302;
  opacity: 1.25;
}

.distance-btn {
  justify-content: space-around;
  width: 100%;

  .bg-primary {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    background: #e3f6f5 !important;
    padding: 4px 3px;
    border: none !important;
  }
}

.like-dislike-btn {
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.blueBackground {
  border: 1px solid #e3f6f5;
  border-radius: 10px;
  background-color: #e3f6f5;
  padding: 8px;
}

.yellowBackground {
  border: 1px solid #ffc533;
  border-radius: 10px;
  background-color: #ffc533;
  padding: 8px;
}

.header-Bg {
  border: 1px solid #ffc533;
  border-radius: 10px;
  padding: 5px;
  background: #ffc533;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerBgBlue {
  border: 1px solid #e3f6f5;
  border-radius: 10px;
  padding: 5px;
  background: #e3f6f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.extraSpace {
  padding: 30px;
}

.toast-head {
  position: fixed;
  z-index: 1;
}

.likeDiv {
  display: flex;
  gap: 1rem;
  align-items: center;
  position: absolute;
  bottom: 10px;
  padding-left: 25px;
  z-index: 1;
}
